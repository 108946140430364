import { Route } from 'vue-router'
import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'

export const alertsGuard = (_to: Route, _from: Route, next: any): void => {
  const isPortalUser = store.getters[`${ModuleNamespaces.AUTH}/getIsPortalUser`]
  const userIsAuthenticated = store.getters[`${ModuleNamespaces.AUTH}/userIsAuthenticated`]
  if (userIsAuthenticated && !isPortalUser) {
    store.dispatch(`${ModuleNamespaces.ALERTS_MESSAGES}/checkAlertsMessages`)
  }
  next()
}
