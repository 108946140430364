import { getDataFromLocalStorage } from '@/helpers/helpers'
import { jwtDecode } from 'jwt-decode'

export const isExpiredToken = (token: any) => {
  if (!token && !token.exp) {
    return true
  } 
  return token && token.exp * 1000 < Date.now()
}

export const refreshTokenExpired = () => {
  const authData = getDataFromLocalStorage('auth-data')
  const refreshToken = (authData && authData.refreshToken) || null
  const refreshTokenDecoded: { [key: string]: any } = refreshToken ? jwtDecode(refreshToken) : {}
  
  return isExpiredToken(refreshTokenDecoded)
}