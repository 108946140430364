import { render, staticRenderFns } from "./ActionTypeTemplateComponent.vue?vue&type=template&id=fc2e0248&scoped=true&lang=pug"
import script from "./ActionTypeTemplateComponent.vue?vue&type=script&lang=ts"
export * from "./ActionTypeTemplateComponent.vue?vue&type=script&lang=ts"
import style0 from "./ActionTypeTemplateComponent.vue?vue&type=style&index=0&id=fc2e0248&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc2e0248",
  null
  
)

export default component.exports