import { useHistoryStore } from '@/stores/router/useHistoryStore'
import { Route } from 'vue-router'
import { URLS } from '@/router/routes/urlRoutes'

export const historyGuard = (to: Route, _from: Route, next: () => void): void => {
  try {
    if (![URLS.LOGIN, URLS.DOUBLE_OPTIN, URLS.NAVISION_LOGIN, URLS.EXTERNAL_LOGOUT].includes(to.name as string)) {
      const store = useHistoryStore()
      store.addHistory(to)
    }
  } catch (error) {
    console.log({error})
  }
  next()
}
