import { TranslateResult } from 'vue-i18n'

export interface DialogState {
  dialogData: DialogData | null
  customDialogData: CustomDialogData | null
  showDialog: boolean
  showCustomDialog: boolean
}
export interface DialogData {
  action?: () => void
  onCloseCustomAction?: () => void
  hideSecondaryButton?: boolean
  icon?: string
  mainButtonText?: TranslateResult
  message: string
  secondaryButtonText?: TranslateResult
  title?: string
  type?: string
  buttons?: DialogButton[],
  notMoveToTop?: boolean
}

export interface CustomDialogData {
  name: string
  props?: object
  styles?: object
}

export enum DialogTypes {
  CUSTOM_DIALOG = 'custom-dialog-text',
  ERROR = 'error-text',
  INFO = 'info-text',
  SUCCESS = 'success-text',
  WARNING = 'warning-text'
}

export const dialogMutationTypes = {
  CLOSE_DIALOG: 'CLOSE_DIALOG',
  CLOSE_CUSTOM_DIALOG: 'CLOSE_CUSTOM_DIALOG',
  SHOW_DIALOG: 'SHOW_DIALOG',
  SHOW_CUSTOM_DIALOG: 'SHOW_CUSTOM_DIALOG'
}

export interface DialogButton {
  style?: string
  variant?: string
  text: string | TranslateResult
  type?: string
  action?: () => void
}

export enum DialogButtonTypes {
  CLOSE = 'close',
  MAIN = 'main-action-button',
  SECONDARY = 'secondary-action-button'
}

export enum CustomDialogComponentName {
  ASSOCIATE_ACTION = 'AssociateActionDialogComponent',
  CERTIFY_DOCUMENT = 'CertifyDocumentFirmaDialogComponent',
  CONTACT_MINI_FORM = 'ContactMiniFormComponent',
  DUPLICATE_ACTION = 'DuplicateActionDialogComponent',
  DUPLICATE_EXPEDIENT = 'DuplicateExpedientDialogComponent',
  GLOBAL_VISION_PROFITABILITY = 'GlobalVisionProfitabilityDialogComponent',
  LOADING_DOCS_IA = 'LoadingDocsIADialogComponent',
  MONITORING_AND_AUDITS = 'MonitoringAndAuditsFirmaDialogComponent',
  MOVE_ACTION = 'MoveActionDialogComponent',
  NOTIFICATION_INTERN = 'SendNotificationMailInternComponent',
  SEND_CERTIFIED_EMAIL = 'SendCertifiedEmailFirmaDialogComponent',
  SEND_CERTIFIED_SMS = 'SendCertifiedSMSFirmaDialogComponent',
  SEND_TO_FIRMA = 'SendToFirmaDialogComponent',
  SUB_ACTION_SELECTOR = 'SubActionSelectorDialogComponent',
  VIEW_ECONOMICDATA_ACTIONS = 'ViewEconomicDataActionsDetailDialogComponent',
  VIEW_INVOICE_ACTIONS = 'ViewInvoiceActionsDetailDialogComponent'
}

export enum LOAD_STATE {
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  NO_DATA = 'NO_DATA'
}
