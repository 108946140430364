<template lang="pug">

section(v-if="!!(report && report.config && report.config.dataSource)" class="lexon-view-container")
  AlertComponent(
      :whereIsRendered="componentWhereIsRenderedAlertComponent"
    )
  ReportFilterComponent(
    :filters="filters"
    :title="reportName"
    :breadcrumbs="breadcrumbItems"
    :goBackRouteName="goBackRouteName"
    @filter="reloadPivotTable"
    @clear-table="onClearTable"
  )
  GridPivotTableComponent(
    :key="pivotKey"
    :title="$t('views.reports.title')"
    :dataSourceSettings="report"
    :fetchSettings="fetchReportDataAction"
    :saveSettings="postReportsAction"
    :disableToolbarÏtems="disableToolbarÏtems"
    @reload-pivot-table="reloadPivotTable"
  )

</template>

<script lang="ts">
import { mixins } from 'vue-class-component'
import { Component } from 'vue-property-decorator'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import ListViewMixin from '@/mixins/ListViewMixin.vue'
import { Action, Getter } from 'vuex-class'
import AlertComponent from '@/components/Alert/AlertComponent.vue'
import GridPivotTableComponent from '@/components/GridPivotTable/GridPivotTableComponent.vue'
import ReportFilterComponent from '@/views/Reports/ReportFilterComponent.vue'
import { URLS } from '@/router/routes/urlRoutes'
import { ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'

const reportsModule: string = ModuleNamespaces.REPORTS

@Component({
  components: {
    AlertComponent,
    GridPivotTableComponent,
    ReportFilterComponent
  }
})
export default class ReportsView extends mixins(ListViewMixin) {
  @Action('fetchReport', { namespace: reportsModule })
  fetchReportAction: (id: any) => []

  @Action('fetchReportData', { namespace: reportsModule })
  fetchReportDataAction: ({ id, filters }: { id: string; filters: object }) => []

  @Action('postReports', { namespace: reportsModule })
  postReportsAction: (report: any) => void

  @Getter('getReport', { namespace: reportsModule }) report: {}

  disableToolbarÏtems = true

  pivotKey = 1

  goBackRouteName = URLS.REPORTS

  breadcrumbItems = [
    {
      text: URLS.REPORTS,
      url: `/${this.goBackRouteName}`
    }
  ]

  componentWhereIsRenderedAlertComponent = ComponentWhereIsRendered.MAIN

  get reportName() {
    return (this as any).report.name
  }

  get filters() {
    return (this as any).report.configFilters
  }

  created() {
    this.fetchReportConfig()
  }

  async fetchReportConfig() {
    await this.fetchReportAction(this.$route.params.selectedRegisterId)
    console.log('fetchReportConfig', this.report)
  }

  async reloadPivotTable(filters: object) {
    await this.fetchReportDataAction({ id: this.$route.params.selectedRegisterId, filters })
    this.disableToolbarÏtems = false
    this.pivotKey++
  }

  onClearTable() {
    console.log('onClearTable')
    this.disableToolbarÏtems = true
    this.fetchReportConfig()
  }
}
</script>
