import { ActionName, CommandModel } from "@/components/grids/LfGrid/LfGridTypes"
import { Icons } from "@/icons/icons"
import { i18n } from "@/plugins/vue-i18n"

export const commandButtons: CommandModel[] = [

  {
    id: ActionName.OPEN_KEBAK_MENU,
    type: 'None',
    title: 'Open',
    buttonOption: {
      iconCss: Icons.KEBAB,
      cssClass: 'custombutton'
    }
  }
]

export const commandButtonsCantDelete: CommandModel[] = [
  {
    id: ActionName.OPEN_NEW_TAB,
    title: i18n.t('action_buttons.open_new_window').toString(),
    type: 'None',
    buttonOption: {
      iconCss: Icons.OPEN_WINDOW,
      cssClass: 'custombutton'
    }
  }
]

