<template>
  <div class="no-preview-component">
    <ClosePreviewComponent @close-viewer-event="closeViewerEvent" />
    <span class="advise">{{ texts.mainAdvise }}</span>
  </div>
</template>

<script setup lang="ts">
import { defineExpose } from 'vue'
import { useI18n } from 'vue-i18n-composable'
import ClosePreviewComponent from '@/components/FileManager/Preview/components/ClosePreviewComponent.vue'

// TRANSLATIONS
const { t } = useI18n()

const texts = {
  mainAdvise: t('components.file_manager.advises.preview_not_valid') as string
}

// EVENTS
const emit = defineEmits(['close-viewer-event'])

// METHODS
function closeViewerEvent() {
  emit('close-viewer-event')
}

function preview() {
  return null
}

defineExpose({
  preview
})
</script>

<style lang="scss" scoped>
.no-preview-component {
  @include flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  padding-left: 8px;

  .advise {
    display: inline-flex;
    align-items: center;
    height: 100%;
    font-family: $corporate-font;
    font-size: 16px;
    color: $neutral-grey-800;
  }
}
</style>
