<template lang="pug">

  section(class="form-generator-container")
    span(v-if="showRequiredFieldsAdvice" class="required-fields-advice") {{ $t('components.form_generator.required_fields') }}

    ValidationObserver(
        :ref="formTag"
        v-slot="{ validate }"
        class="form"
        tag="div"
        autocomplete="off"
        @submit.prevent="mainAction"
      )

      template(v-for="(field, index) in parseSchema")
        template(v-if="field.fieldType === 'LexonHiddenComponent'")
          component(
            class="form-element"
            :is="field.fieldType"
            :value="getFieldValue(field)"
            @input="updateForm(field, $event)"
            v-bind="field"
          )
        template(v-else)
          v-col(
            v-if="field.fieldset"
            cols="12"
            :class="['d-inline-block', 'pa-0', 'form-row']"
          )
            fieldset(
              :class="[{'md-and-up': $vuetify.breakpoint.mdAndUp}, 'fieldset']"
            )
              legend(v-if="getFieldSetName(field)" class="fieldset-legend") {{ field.fieldsetName }}
              v-col(
                v-for="(field, index) in field.fields"
                :key="index"
                :cols="setBreakpoint(field, breakpoints.xs, breakpointsClosedMenu.xs)"
                :sm="setBreakpoint(field, breakpoints.sm, breakpointsClosedMenu.sm)"
                :md="setBreakpoint(field, breakpoints.md, breakpointsClosedMenu.md)"
                :lg="setBreakpoint(field, breakpoints.lg, breakpointsClosedMenu.lg)"
                :xl="setBreakpoint(field, breakpoints.xl, breakpointsClosedMenu.xl)"
                :class="[calculatePaddingWidth, 'd-inline-block', { 'hidden-field': field.hidden }, 'form-row']"
              )
                component(
                  ref="formComponent"
                  class="form-element"
                  :is="field.fieldType"
                  :value="getFieldValue(field)"
                  :context="context"
                  :text="field.buttonText ? field.buttonText : null"
                  @input="updateForm(field, $event)"
                  @blur="emitOnBlurEvent(field, $event)"
                  @execute-action="executeAction"
                  @lexonButtonEvent="lexonButtonEvent"
                  v-bind="field"
                )

          v-col(
            v-else
            :cols="setBreakpoint(field, breakpoints.xs, breakpointsClosedMenu.xs)"
            :sm="setBreakpoint(field, breakpoints.sm, breakpointsClosedMenu.sm)"
            :md="setBreakpoint(field, breakpoints.md, breakpointsClosedMenu.md)"
            :lg="setBreakpoint(field, breakpoints.lg, breakpointsClosedMenu.lg)"
            :xl="setBreakpoint(field, breakpoints.xl, breakpointsClosedMenu.xl)"
            :class="[{ 'full-width': field.fullWidth }, calculatePaddingWidth, 'd-inline-block', 'form-row', { 'hidden-field': field.hidden }]"
          )
            slot(v-if="appendElementId === field.id" name="appendElement")
            component(
              ref="formComponent"
              class="form-element"
              :is="field.fieldType"
              :value="getFieldValue(field)"
              :context="context"
              :text="field.buttonText ? field.buttonText : null"
              @input="updateForm(field, $event)"
              @blur="emitOnBlurEvent(field, $event)"
              @execute-action="executeAction"
              @lexonButtonEvent="lexonButtonEvent"
              v-bind="field"
            )

</template>

<script lang="ts">
// https://rangle.io/blog/how-to-create-data-driven-user-interfaces-in-vue/
// https://codesandbox.io/s/61y919wrk3?from-embed
import { Component, Prop, Watch, Emit, Mixins } from 'vue-property-decorator'
import CheckboxComponent from '@/components/Checkbox/CheckboxComponent.vue'
import LexonButtonComponent from '@/components/forms/fields/LexonButton/LexonButtonComponent.vue'
import LexonColorPickerComponent from '@/components/forms/fields/LexonColorPicker/LexonColorPickerComponent.vue'
import LexonCurrencyComponent from '@/components/forms/fields/LexonCurrency/LexonCurrencyComponent.vue'
import LexonDropdownListOptionsComponent from '@/components/forms/fields/LexonDropdownListOptions/LexonDropdownListOptionsComponent.vue'
import LexonFileUploadComponent from '@/components/forms/fields/LexonFileUpload/LexonFileUploadComponent.vue'
import LexonHiddenComponent from '@/components/forms/fields/LexonHidden/LexonHiddenComponent.vue'
import LexonMaskComponent from '@/components/forms/fields/LexonMask/LexonMaskComponent.vue'
import LexonNumerationComponent from '@/components/forms/fields/LexonNumeration/LexonNumerationComponent.vue'
import LexonNumericComponent from '@/components/forms/fields/LexonNumeric/LexonNumericComponent.vue'
import LexonScheduleComponent from '@/components/forms/fields/LexonSchedule/LexonScheduleComponent.vue'
import LexonSwitchComponent from '@/components/forms/fields/LexonSwitch/LexonSwitchComponent.vue'
import LexonTextAreaComponent from '@/components/forms/fields/LexonTextArea/LexonTextAreaComponent.vue'
import LexonSimpleGridComponent from '@/components/forms/fields/LexonSimpleGrid/LexonSimpleGridComponent.vue'
import LexonSimpleRowComponent from '@/components/forms/fields/LexonSimpleRow/LexonSimpleRowComponent.vue'
import LexonTextFieldComponent from '@/components/forms/fields/LexonTextField/LexonTextFieldComponent.vue'
import LfInfoComponent from '@/components/forms/message/LfInfoComponent.vue'
import TextFieldComponent from '@/components/TextField/TextFieldComponent.vue'
import LfTitleComponent from '@/components/forms/title/LfTitleComponent.vue'
import LfRequiredFiedsLegendComponent from '@/components/forms/fields/LfRequiredFiedsLegend/LfRequiredFiedsLegendComponent.vue'
import { ValidationObserver } from 'vee-validate'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Getter, Mutation } from 'vuex-class'
import { BreakpointTypes, BreakpointClosedMenuTypes } from '@/components/forms/FormGenerator/types/FormGeneratorTypes'
import LexonDatePickerComponent from '@/components/forms/fields/LexonDatePicker/LexonDatePickerComponent.vue'
import LexonDateTimePickerComponent from '@/components/forms/fields/LexonDateTimePicker/LexonDateTimePickerComponent.vue'
import LexonSelectComponent from '@/components/forms/fields/LexonSelect/LexonSelectComponent.vue'
import LexonAutocompleteComponent from '@/components/forms/fields/LexonAutocomplete/LexonAutocompleteComponent.vue'
import ProfitabilityComponent from '@/components/forms/fields/Profitability/ProfitabilityComponent.vue'
import StaticAlertComponent from '@/components/Alert/StaticAlertComponent.vue'
import PermissionsMixin from '@/mixins/PermissionsMixin.vue'
import useParentFields from '@/composables/useParentFields'

const menusModule = ModuleNamespaces.MENUS
const formsModule = ModuleNamespaces.FORMS
const selectedRegisterModule = ModuleNamespaces.SELECTED_REGISTER

/*
TODO: Import for tests
LexonAutocompleteComponent: () => import('
  @/components/forms/fields/LexonAutocomplete/LexonAutocompleteComponent.vue'),
LexonDatePickerComponent: () => import('
  @/components/forms/fields/LexonDatePicker/LexonDatePickerComponent.vue'),
LexonDateTimePickerComponent: () => import('
  @/components/forms/fields/LexonDateTimePicker/LexonDateTimePickerComponent.vue'),
LexonSelectComponent: () => import('
  @/components/forms/fields/LexonSelect/LexonSelectComponent.vue'),
*/

@Component({
  components: {
    CheckboxComponent,
    LexonAutocompleteComponent,
    LexonButtonComponent,
    LexonColorPickerComponent,
    LexonCurrencyComponent,
    LexonDatePickerComponent,
    LexonDateTimePickerComponent,
    LexonDropdownListOptionsComponent,
    LexonFileUploadComponent,
    LexonHiddenComponent,
    LexonMaskComponent,
    LexonNumerationComponent,
    LexonNumericComponent,
    LexonScheduleComponent,
    LexonSelectComponent,
    LexonSimpleGridComponent,
    LexonSimpleRowComponent,
    LexonSwitchComponent,
    LexonTextAreaComponent,
    LexonTextFieldComponent,
    LfInfoComponent,
    LfRequiredFiedsLegendComponent,
    LfTitleComponent,
    ProfitabilityComponent,
    StaticAlertComponent,
    TextFieldComponent,
    ValidationObserver
  }
})
export default class FormGeneratorComponent extends Mixins(PermissionsMixin) {
  @Prop({
    type: Array,
    required: true
  })
  schema!: any

  @Prop({
    type: Object,
    default: () => ({})
  })
  fieldValues!: object

  @Prop({
    type: String,
    default: () => ({})
  })
  context!: string

  @Prop({
    type: String,
    default: 'form'
  })
  formTag!: string

  @Prop({
    type: Boolean,
    default: false
  })
  validateOnLoad!: boolean

  @Prop({
    type: Number,
    default: 0
  })
  permissionsEntity!: number

  @Prop({
    type: Boolean,
    default: false
  })
  showRequiredFieldsAdvice!: boolean

  @Prop({
    type: String
  })
  appendElementId!: string

  @Prop({
    type: String
  })
  parentField!: string

  @Getter('tryToValidateContactForm', { namespace: formsModule })
  validateContactFormFlag: string

  @Getter('getSelectedRegisterData', { namespace: selectedRegisterModule })
  selectedRegisterData: (context: string) => any

  @Getter('getDrawerStatus', { namespace: menusModule })
  drawerInMiniStatus: boolean

  @Mutation('RESET_VALIDATE_FORM_FLAG', { namespace: formsModule })
  resetValidateFormFlag: () => void

  @Mutation('SET_FORM_IS_VALID', { namespace: formsModule })
  setFormIsValid: (context: string) => void

  @Mutation('RESET_FORM_IS_VALID', { namespace: formsModule })
  setFormIsInValid: (context: string) => void

  @Mutation('SET_RELATED_DATA', { namespace: formsModule })
  saveRelatedData: ({}) => void

  validator: InstanceType<typeof ValidationObserver>

  formData = this.fieldValues

  breakpoints = {
    xs: BreakpointTypes.XS,
    sm: BreakpointTypes.SM,
    md: BreakpointTypes.MD,
    lg: BreakpointTypes.LG,
    xl: BreakpointTypes.XL
  }

  breakpointsClosedMenu = {
    xs: BreakpointClosedMenuTypes.XS,
    sm: BreakpointClosedMenuTypes.SM,
    md: BreakpointClosedMenuTypes.MD,
    lg: BreakpointClosedMenuTypes.LG,
    xl: BreakpointClosedMenuTypes.XL
  }

  @Emit()
  emitOnBlurEvent(field: any, value: string) {
    return { field, value }
  }

  @Emit('lexonButtonEvent')
  lexonButtonEvent() {}

  @Emit()
  executeAction(value: string) {
    return value
  }

  @Watch('validateContactFormFlag')
  validateForm() {
    this.validator.validate()
  }

  @Watch('selectedRegisterData', { deep: true, immediate: true })
  assignFormFieldValues() {
    this.formData = this.fieldValues
  }

  get parseSchema(): any {
    if (this.permissionsEntity) {
      return this.checkFormPermissions(this.schema, this.permissionsEntity)
    }
    return this.schema
  }

  get formIsValidFlag() {
    const isValid = this.validator.flags.valid
    return isValid
  }

  get calculatePaddingWidth() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
        return 'pa-0'
      case 'sm':
        return 'pa-0'
      case 'md':
        return 'pa-0 px-2'
      case 'lg':
        return 'pa-0 px-2'
      case 'xl':
        return 'pa-0 px-2'
    }
  }

  get checkIfSomeFieldIsFilled() {
    return (
      Object.values(this.fieldValues).some((o) => o !== undefined) &&
      Object.values(this.fieldValues).some((o) => o !== null)
    )
  }

  created() {
    this.$emit('createdForm', {
      schema: this.schema,
      formData: this.formData
    })
  }

  mounted() {
    this.validator = (this as any).$refs[this.formTag]

    if (this.validateOnLoad) {
      this.checkIfFormIsValid()
    }

    if (this.checkIfSomeFieldIsFilled) {
      this.checkIfFormIsValid()
      this.$emit('mountedForm', {
        isNewForm: false,
        schema: this.schema,
        formData: this.formData
      })
    } else {
      this.$emit('mountedForm', {
        isNewForm: true,
        schema: this.schema,
        formData: this.formData
      })
    }

    this.setParentFieldValuesData()
    this.$watch('formIsValidFlag', this.checkIfFormIsValid)
  }

  setParentFieldValuesData(fieldName: string = '', value: any = null) {
    const { setParentFieldValues, getParentFieldValues, setParentFormContext, getParentFormContext } = useParentFields()
    const context = getParentFormContext()
    let parentFieldValues: any = getParentFieldValues()

    if (!parentFieldValues && !fieldName) {
      const fieldValues: any = { ...this.fieldValues }
      setParentFormContext(this.context)
      setParentFieldValues(fieldValues)
    } else if (context === this.context && parentFieldValues && fieldName && parentFieldValues[fieldName] !== value) {
      parentFieldValues[fieldName] = value
      setParentFieldValues(parentFieldValues)
    }
  }

  getSelectedParentFieldValueData(fieldName: string) {
    const { getSelectedParentFieldValue } = useParentFields()
    return getSelectedParentFieldValue(fieldName)
  }

  beforeDestroy() {
    this.resetValidateFormFlag()
    this.setFormIsInValid(this.context)
  }

  calculateBreakpoint(field: any, breakpointType: string, breakpointClosedMenuType: string) {
    let breakpoint: number | null = null

    if (field[breakpointType] !== undefined) {
      breakpoint = field[breakpointType]
    }
    if (this.drawerInMiniStatus && field[breakpointClosedMenuType] !== undefined) {
      breakpoint = field[breakpointClosedMenuType]
    }

    return breakpoint
  }

  setBreakpoint(field: any, breakpointType: string, breakpointClosedMenuType: string) {
    let breakpoint = this.calculateBreakpoint(field, breakpointType, breakpointClosedMenuType)

    if (breakpoint !== null) {
      return breakpoint
    }

    if (field.fullWidth) {
      return 12
    }

    switch (breakpointType) {
      case BreakpointTypes.XS:
      case BreakpointTypes.SM:
        breakpoint = 12
        break
      case BreakpointTypes.MD:
        breakpoint = 6
        break
      case BreakpointTypes.LG:
        breakpoint = this.drawerInMiniStatus ? 4 : 6
        break
      case BreakpointTypes.XL:
        breakpoint = this.drawerInMiniStatus ? 3 : 4
        break
      default:
        breakpoint = 12
        break
    }

    return breakpoint
  }

  updateForm(field: any, value: any) {
    this.setRelatedData(field, value)
    const name = field.name.toString()
    if (name.includes('.')) {
      const props = name.split('.')
      let objectValue = (this as any).formData[props[0]]
      if (typeof objectValue === 'undefined') {
        objectValue = {}
      }
      objectValue[props[1]] = value
      this.$set(this.formData, props[0], '')
      this.$set(this.formData, props[0], objectValue)
    } else {
      this.$set(this.formData, field.name, value)
    }

    this.setParentFieldValuesData(name, value)
    this.$emit('saveFormData', this.schema, this.formData, field, value)
  }

  getFieldSetName(field: any) {
    return field?.hidden ? '' : field.fieldsetName
  }

  getFieldValue(field: any) {
    const name = field.name.toString()
    if (name.includes('.')) {
      const props = name.split('.')
      let innerField = null
      for (const prop of props) {
        if (null !== innerField) {
          innerField = innerField[prop]
        } else if ((this as any).formData[prop]) {
          innerField = (this as any).formData[prop]
        } else {
          break
        }
      }
      return innerField
    }
    return (this as any).formData[field.name]
  }

  setRelatedData(field: any, value: any) {
    if (field.hasRelatedField) {
      let data
      if (value) {
        data = value[field.itemRelatedField]
      }
      this.saveRelatedData({
        data,
        relatedAlias: field.name
      })
    }
  }

  async checkIfFormIsValid() {
    const formIsValid = await this.validator.validate()
    if (formIsValid) {
      this.setFormIsValid(this.context)
      this.$emit('isValidForm', true)
    } else {
      this.setFormIsInValid(this.context)
      this.$emit('isValidForm', false)
    }
    return formIsValid
  }

  async mainAction() {
    const formIsValid = await (this as any).validator.validate()

    if (formIsValid) {
      // console.log('Es valido')
    } else {
      // console.log('no es valido')
    }
  }
}
</script>

<style lang="scss" scoped>
.form-generator-container {
  @include flex($flex-direction: column);
  @include required-form-field;

  .form {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2px;
    width: 100%;
  }

  .form-element {
    @include flex;
  }

  .fieldset {
    border: none;
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;

    &.md-and-up {
      padding: 0;
    }

    .fieldset-legend {
      @include milano-bold-18;
      color: $main-1000;
      margin-left: 8px;
      margin-bottom: $spacing-md;
      padding-top: $spacing-md;
    }
  }

  .hidden-field {
    display: none !important;
  }

  ::v-deep .compressed {
    .v-input {
      padding: 0;
      margin: 0;
    }
  }

  @include untilDesktopLarge {
    .hidden-field {
      display: block !important;
    }
  }
}
</style>
