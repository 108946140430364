<template lang="pug">

//- BUTTONS
div(class="custom-dialog-action-buttons-container")
  LfLabelButtonComponent(
    :text="secondaryButtonTextRendered"
    :action="() => closeCustomDialog()"
    :variant="variant.outlined"
  )
  LfLabelButtonComponent(
    v-if="mainAction"
    :text="primaryButtonTextRendered"
    :action="() => onMainBtnClicked()"
    :disabled="disabledButtons"
    :variant="variant.solid"
  )
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { LabelButtonVariant } from '@/components/forms/buttons/types/ButtonTypes'
import LfLabelButtonComponent from '@/components/forms/buttons/label/LfLabelButtonComponent.vue'

const dialogModule: string = ModuleNamespaces.DIALOG

@Component({
  components: {
    LfLabelButtonComponent
  }
})
export default class CustomDialogActionButtonsComponent extends Vue {
  @Prop({
    type: Boolean
  })
  disabledButtons!: boolean

  @Prop({
    type: Function
  })
  mainAction!: () => void

  @Prop({
    type: String
  })
  primaryButtonText!: string

  @Prop({
    type: String
  })
  secondaryButtonText!: string

  @Prop({
    type: Boolean,
    default: true
  })
  clickOnce!: boolean

  @Action('closeCustomDialog', { namespace: dialogModule })
  closeCustomDialog: () => {}

  variant = {
    outlined: LabelButtonVariant.OUTLINED,
    solid: LabelButtonVariant.SOLID
  }

  texts = {
    cancel: this.$t('action_buttons.cancel'),
    save: this.$t('action_buttons.save')
  }

  clickedOnce = false

  get primaryButtonTextRendered() {
    return this.primaryButtonText || this.texts.save
  }

  get secondaryButtonTextRendered() {
    return this.secondaryButtonText || this.texts.cancel
  }

  onMainBtnClicked() {
    if (this.clickOnce && !this.clickedOnce) {
      this.mainAction()
      this.clickedOnce = true
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-dialog-action-buttons-container {
  @include flex($justify-content: flex-end);
  gap: 8px;
}
</style>
