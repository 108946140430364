import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { authLexonPartnerId, authLexonToken } from '@/store/modules/auth/authTypes'
import { getDataFromLocalStorage } from '@/helpers/helpers'
import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { URLS } from '@/router/routes/urlRoutes'
import md5 from 'md5'
import { refreshTokenExpired } from '@/helpers/token'


export const securityHeaders = {
  'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
  'Content-Security-Policy': 'script-src "self"',
  'X-Frame-Options': 'DENY',
  'X-Content-Type-Options': 'nosniff',
  'Referrer-Policy': 'strict-origin-when-cross-origin',
  'Permissions-Policy': `geolocation=(self "${ process.env.VUE_APP_API_URL }")`,
  'Cross-Origin-Embedder-Policy': 'require-corp',
  'Cross-Origin-Opener-Policy': 'same-origin-allow-popups',
  'Clear-Site-Data': '"cookies", "cache"',
  'X-Permitted-Cross-Domain-Policies': 'none',
  'Cache-Control': 'no-store, no-cache, must-revalidate, proxy-revalidate'
}

const axiosConfiguration: AxiosRequestConfig = {
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Accept': 'application/json',
    ...securityHeaders
  }
}

// TODO: En header
if (axiosConfiguration && axiosConfiguration.headers) {
  axiosConfiguration.headers[authLexonPartnerId] = process.env.VUE_APP_LEXON_PARTNER_ID
}

const http = axios.create(axiosConfiguration)

type fn = (param: string) => void

const authData = getDataFromLocalStorage('auth-data')
let accessToken = ''
if (authData) {
  accessToken = authData.accessToken
  http.defaults.headers.common['Authorization'] = `Bearer ${ accessToken }`
}

let subscribers: fn[] = []

export function onAccessTokenRefreshed(accessToken: string) {
  http.defaults.headers.common['Authorization'] = `Bearer ${ accessToken }`
  subscribers = subscribers.filter((callback) => callback(accessToken))
}

function addSubscriber(callback: () => void) {
  subscribers.push(callback)
}

http.interceptors.request.use(config => {
  const needAuthorization = config.headers.Authorization
    && (config as any).headers.Authorization.startsWith('Bearer')
    && !config.headers[authLexonToken]

  if (!needAuthorization) {
    return config
  }

  try {
    if (refreshTokenExpired()) {
      store.dispatch(`${ ModuleNamespaces.AUTH }/logout`)
    }
  } catch (error) {}

  return config
}, error => {
  return Promise.reject(error);
})

http.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    try {
      const {
        config,
        response: { status }
      } = error
      const originalRequest = config
      if (
        !config.url.includes('login') &&
        !config.url.includes('navision-login') &&
        !config.url.includes(URLS.SELECT_COMPANY) &&
        !config.url.includes('navision/encrypted') &&
        !config.url.includes('actions') &&
        !config.url.includes('v2/token-exchange') &&
        !config.url.includes('sign/start') &&
        !config.url.includes('/lexonsso/logout') &&
        !config.url.includes('lexonsso/logout') &&
        status === 401
      ) {
        if (!config.url.includes('auth/token/get')) {
          store.dispatch(`${ ModuleNamespaces.AUTH }/refreshToken`).then(() => {
            if (getDataFromLocalStorage('auth-data')) {
              accessToken = getDataFromLocalStorage('auth-data').accessToken
              onAccessTokenRefreshed(accessToken)
            }
          })
        } else {
          store.dispatch(`${ ModuleNamespaces.AUTH }/logout`)
        }

        const retryOriginalRequest = new Promise((resolve) => {
          addSubscriber(() => {
            originalRequest.headers.Authorization = `Bearer ${ accessToken }`
            resolve(http(originalRequest))
          })
        })
        return retryOriginalRequest
      }
    } catch (error) {
    }
    return Promise.reject(error)
  }
)

http.interceptors.request.use(
  (config) => {
    const accessToken = store.getters[`${ ModuleNamespaces.AUTH }/getAuthToken`]

    if (accessToken) {
      const source = axios.CancelToken.source()
      config.cancelToken = source.token
      http.defaults.headers.common['Authorization'] = `Bearer ${ accessToken }`

      // NO CANCELA LA PETICIÓN DEL LISTADO DE AUDITORÍAS
      if (!config.url!.includes('audits/get')) {
        store.commit(`${ ModuleNamespaces.AUTH }/ADD_CANCEL_TOKEN`, source)
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// CACHEAR PETICIONES GET DURANTE 1 SEGUNDO
const cache: { [key: string]: { timestamp: number; response: AxiosResponse } } = {}

http.interceptors.request.use(
  (config) => {
    // Solo continuar con la caché si el método es GET
    if (config.method && config.method.toLowerCase() === 'get') {
      // Crear una clave única para la caché basada en la URL y los parámetros de la petición
      const cacheKey = generateCacheKey(config)

      // Comprobar si la respuesta de esta petición ya está en la caché y si es reciente
      if (cache[cacheKey] && (Date.now() - cache[cacheKey].timestamp) < 3000) {
        // Cancelar la petición actual y devolver la respuesta desde la caché
        const axiosError = new axios.Cancel(`Cached: ${ cacheKey }`) as any
        axiosError.response = cache[cacheKey].response
        return Promise.reject(axiosError)
      }
    }
    // Si la caché es antigua, no existe o si el método no es GET, seguir con la petición
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

http.interceptors.response.use(
  (response) => {
    // Verificar si la petición es de tipo GET antes de cachear
    if (response.config.method && response.config.method.toLowerCase() === 'get') {
      // Si la petición fue exitosa y es un GET, guardar la respuesta en la caché con una marca de tiempo
      cache[generateCacheKey(response.config)] = {
        timestamp: Date.now(),
        response: response
      }
    }

    // Devolver la respuesta
    return response
  },
  (error) => {
    if (!axios.isCancel(error)) {
      // Manejo estándar de errores
      return Promise.reject(error)
    }

    // Si la cancelación fue por una petición de tipo GET previamente cachéada, devolver la respuesta desde la caché
    if (error.message?.startsWith('Cached:')) {
      return (error as any).response
    }

    // Otros errores de cancelación
    return Promise.reject(error)
  }
)

function generateCacheKey(config: AxiosRequestConfig): string {
  return md5(
    config.params
      ? `${ config.url }${ JSON.stringify(config.params) }`
      : `${ config.url }`
  )
}

export default http
