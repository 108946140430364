<template lang="pug">

  section(class="lexbox-redirect-container")

    HeaderComponent

    section(class="lexbox-redirect-section")

</template>

<script lang="ts">
import { Vue, Prop, Component } from 'vue-property-decorator'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Action, Getter, Mutation } from 'vuex-class'
import { URLS } from '@/router/routes/urlRoutes'
import HeaderComponent from '@/components/Header/HeaderComponent.vue'
import { entity } from '@/store/modules/entities/entitiesTypes'

const actionsModule = ModuleNamespaces.ACTIONS
const appointmentsModule = ModuleNamespaces.APPOINTMENTS
const authModule = ModuleNamespaces.AUTH
const expedientsModule = ModuleNamespaces.EXPEDIENTS
const lexboxModule = ModuleNamespaces.LEXBOX
const spinnerModule = ModuleNamespaces.SPINNER

@Component({
  components: {
    HeaderComponent
  }
})
export default class LexboxRedirectView extends Vue {
  @Prop({
    type: String
  })
  appointmentId!: string

  @Getter('getAction', { namespace: actionsModule })
  action: []

  @Getter('getAppointment', { namespace: appointmentsModule })
  appointment: []

  @Getter('getCalendarServiceUrl', { namespace: lexboxModule })
  serviceUrl: string

  @Getter('getCompanyAppointmentsVersion', { namespace: authModule })
  calendarVersion: number

  @Getter('getCompanyDataBase', { namespace: authModule })
  companyDatabase: number

  @Getter('getCompanyId', { namespace: authModule })
  selectedCompanyId: number

  @Getter('getEventCalendarServiceUrl', { namespace: lexboxModule })
  eventServiceUrl: string

  @Getter('getProUserId', { namespace: authModule })
  idProUser: string

  @Getter('getAccessToken', { namespace: lexboxModule }) accessToken: string

  @Action('fetchAccessToken', { namespace: lexboxModule })
  fetchAccessToken: (calendarQuery: object) => void

  @Action('fetchAppointment', { namespace: appointmentsModule })
  fetchAppointment: (type: string) => void

  @Action('fetchAction', { namespace: actionsModule })
  fetchAction: (type: string) => void

  @Action('fetchCalendarServiceUrl', { namespace: lexboxModule })
  fetchServiceUrl: (calendarQuery: object) => void

  @Action('fetchExpedientsIntervenerFiltered', { namespace: expedientsModule })
  fetchExpedientsIntervener: (idExpedient: {}) => number

  @Mutation('SHOW_SPINNER', { namespace: spinnerModule })
  showSpinner: (showText: boolean) => void

  async created() {
    this.showSpinner(true)
    if (this.$route.meta?.title === URLS.EMAIL) {
      if (this.$route.params.idAction) {
        await this.generateEditMailToken(this.$route.params.idAction)
      } else if (this.$route.params.idEntity) {
        await this.generateNewMailToken(this.$route.params.idEntity)
      } else {
        await this.generateNewToken()
      }
      window.location.replace(`${process.env.VUE_APP_LEXBOX_ACCESS}${this.accessToken}`)
      return
    }
    if (this.$route.meta?.title === `${URLS.EMAIL}-${URLS.NEW}`) {
      await this.generateNewActionMailToken()
      window.location.replace(`${process.env.VUE_APP_LEXBOX_ACCESS}${this.accessToken}`)
      return
    }
    const id = this.appointmentId || this.$route.query.id

    const calendarParams = { id: '', idProUser: this.idProUser, query: {}, provider: null }
    if (id) {
      await this.fetchAppointment(id.toString())
      calendarParams.query = {
        idEvent: (this as any).appointment.idEvent,
        idActuation: (this as any).appointment.idAction,
        idCalendar: (this as any).appointment.idCalendar,
        calendarName: (this as any).appointment.calendarName,
        account: (this as any).appointment.account || null,
        startDate: (this as any).appointment.startDate || null,
        endDate: (this as any).appointment.endDate || null,
        iCalUId: (this as any).appointment.iCalUId || null,
        origin: URLS.AGENDA
      }
      calendarParams.provider = (this as any).appointment.provider
      calendarParams.id = id.toString()
    } else {
      calendarParams.query = { origin: URLS.AGENDA }
    }
    await this.fetchServiceUrl(calendarParams)
    let url = calendarParams.query ? this.eventServiceUrl : this.serviceUrl
    if (url === null) {
      if (this.accessToken === '') {
        await this.generateNewToken()
      }
      url = `${process.env.VUE_APP_LEXBOX_ACCESS}${this.accessToken}`
    }
    window.location.replace(url)
  }

  async generateNewToken() {
    const accessParams = {
      idClienteNavision: this.idProUser,
      env: process.env.VUE_APP_LEXBOX_ENV ? process.env.VUE_APP_LEXBOX_ENV : '',
      bbdd: this.companyDatabase,
      idCompany: this.selectedCompanyId
    }
    await this.fetchAccessToken(accessParams)
  }

  async generateNewMailToken(idEntity: string) {
    const interveners = await this.fetchExpedientsIntervener({
      selectedRegisterId: idEntity
    })
    const accessParams = {
      idClienteNavision: this.idProUser,
      env: process.env.VUE_APP_LEXBOX_ENV ? process.env.VUE_APP_LEXBOX_ENV : '',
      idEntity,
      idEntityType: entity.expedients.type,
      bbdd: this.companyDatabase,
      idCompany: this.selectedCompanyId,
      interveners
    }
    await this.fetchAccessToken(accessParams)
  }

  async generateNewActionMailToken() {
    const accessParams = {
      idClienteNavision: this.idProUser,
      env: process.env.VUE_APP_LEXBOX_ENV ? process.env.VUE_APP_LEXBOX_ENV : '',
      idEntityType: entity.actions.emails.type,
      bbdd: this.companyDatabase,
      idCompany: this.selectedCompanyId
    }
    await this.fetchAccessToken(accessParams)
  }

  async generateEditMailToken(idAction: string) {
    await this.fetchAction(idAction.toString())
    const mailData = JSON.parse((this as any).action.uid)
    const accessParams = {
      idClienteNavision: this.idProUser,
      env: process.env.VUE_APP_LEXBOX_ENV ? process.env.VUE_APP_LEXBOX_ENV : '',
      mailAccount: mailData.MailAccount,
      provider: mailData.Provider,
      folder: mailData.Folder,
      idMail: mailData.Uid,
      bbdd: this.companyDatabase,
      idCompany: this.selectedCompanyId
    }
    await this.fetchAccessToken(accessParams)
  }
}
</script>

<style lang="scss" scoped>
.lexbox-redirect {
  &-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &-section {
    @include flex;
    flex-direction: column;
  }

  &-card {
    @include flex($flex-direction: column, $justify-content: flex-start);
    @include borders;
    width: 450px;
    padding-top: 50px;
  }
}
</style>
