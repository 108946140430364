<template>
  <section class="email-preview-container">
    <SpinnerLayer v-if="loading" />
    <div v-else class="email-preview">
      <ClosePreviewComponent @close-viewer-event="closeViewerEvent" />
      <div class="email" v-html="htmlContent"></div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { defineExpose, ref } from 'vue'
import { DocumentPreview } from '@/components/FileManager/Preview/types/PreviewTypes'
import { MainService } from '@/services/MainService'
import ClosePreviewComponent from '@/components/FileManager/Preview/components/ClosePreviewComponent.vue'
import SpinnerLayer from '@/components/Spinner/SpinnerLayerComponent.vue'

// DATA
const htmlContent = ref('')
const loading = ref(true)

// EVENTS
const emit = defineEmits(['close-viewer-event', 'failPreview'])

// METHODS
function closeViewerEvent() {
  emit('close-viewer-event')
}

function onServiceFailure() {
  emit('failPreview')
}

async function fetchEmailHTMLTemplate(documentId: number) {
  const url = `/v2/document/${documentId}/email-to-html`
  const { data } = await new MainService().getData(url)
  htmlContent.value = data.htmlContent
}

async function preview(documentPreview: DocumentPreview) {
  try {
    loading.value = true
    const { id } = documentPreview
    await fetchEmailHTMLTemplate(id)
  } catch (error) {
    return onServiceFailure()
  } finally {
    loading.value = false
  }
}

defineExpose({
  preview
})
</script>

<style lang="scss" scoped>
.email-preview-container {
  width: auto;
  width: 100%;
  height: 100%;

  .email-preview {
    width: 100%;
    height: 100%;
    padding: 0 $spacing-xs;

    .email {
      @include scroll-styles;
      width: 100%;
      max-height: 100%;
      overflow-y: scroll;
      padding: 1em 1.5em;
    }
  }
}
</style>
