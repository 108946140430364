<template lang="pug">

div(class="custom-dialog-header-container")

  header(class="header")
    span(class="header-title") {{ headerTitle }}

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class CustomDialogHeaderComponent extends Vue {
  @Prop({
    type: String,
    required: true
  })
  headerTitle!: string
}
</script>

<style lang="scss" scoped>
.custom-dialog-header-container {
  display: flex;
  min-height: 46px;
  padding: $spacing-xs;
  align-items: center;
  border-radius: $cornerRadius-sm;
  background: $main-1000;
  margin-bottom: $spacing-xl;

  .header {
    display: flex;
    padding: 0px $spacing-xs;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    .header-title {
      @include milano-bold-18;
      overflow: hidden;
      color: $neutral-white-000;
      text-overflow: ellipsis;
    }
  }
}
</style>
