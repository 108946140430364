<script lang="ts">
import { mixins } from 'vue-class-component'
import { Component, Prop } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import { Action } from 'vuex-class'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import PortalUserMixin from '@/mixins/PortalUserMixin.vue'
import PermissionsMixin from '@/mixins/PermissionsMixin.vue'
import { TranslateResult } from 'vue-i18n'
import { renderContextMenuGridItemsHelper } from '@/helpers/contextMenuGridItems'
import { ContextMenuItemModel } from '@syncfusion/ej2-vue-grids'
import { ContextMenuItem } from '@/store/modules/contextMenu/contextMenuTypes'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'

const dialogModule: string = ModuleNamespaces.DIALOG

interface ContextMenuOption {
  id?: ActionName
  text: TranslateResult
  iconCss: string
  action?: ({}: {}) => {}
}

@Component
export default class GridContextMenuOptionsMixin extends mixins(PortalUserMixin, PermissionsMixin) {
  @Prop({
    type: Array
  })
  contextMenuItems!: ContextMenuItem[]

  @Prop({
    type: Array
  })
  databaseContextMenuItems!: string[] | null

  @Prop({
    type: Boolean,
    default: false
  })
  disableContextMenu!: boolean

  @Action('showDialog', { namespace: dialogModule })
  showDialog: ({}) => {}

  @Action('showCustomDialog', { namespace: dialogModule })
  showCustomDialog: ({}) => {}

  get contextMenuOptionsTexts() {
    return {
      associateAction: this.$t('components.context_menu.associate_action'),
      billable: this.$t('components.context_menu.billable'),
      changeStatus: this.$t('components.context_menu.change_status'),
      duplicateAction: this.$t('components.context_menu.duplicate_action'),
      editCourt: this.$t('components.context_menu.edit_court'),
      editIntervener: this.$t('components.context_menu.edit_intervener') as string,
      editItem: this.$t('components.context_menu.edit'),
      lookItem: this.$t('components.context_menu.look'),
      moveAction: this.$t('components.context_menu.move_action'),
      noBillable: this.$t('components.context_menu.no_billable'),
      open: this.$t('components.context_menu.open'),
      openLexnet: this.$t('components.context_menu.open_lexnet'),
      openLfMail: this.$t('components.context_menu.open_lf_mail'),
      openWindow: this.$t('components.context_menu.open_window'),
      removeItem: this.$t('components.context_menu.remove'),
      separator: '',
      viewActionsAssociated: this.$t('components.context_menu.view_actions_associated '),
      viewActionsDetail: this.$t('components.context_menu.view_actions_detail'),
      viewActionsImported: this.$t('components.context_menu.view_actions_imported')
    }
  }

  get showDeleteContextMenuOption() {
    return !this.isPortalUser && Boolean(this.viewPermission.canDelete)
  }

  get showLookRegisterContextMenuOption(): boolean {
    return !this.isPortalUser && !Boolean(this.viewPermission.canSave)
  }

  get showEditContextMenuOption(): boolean {
    return !this.isPortalUser && Boolean(this.viewPermission.canSave)
  }

  get contextMenuOptionsWithoutOpenWindow() {
    return this.contextMenuItemsDefault.filter((item: any) => {
      return item.text && item.text !== this.contextMenuOptionsTexts.openWindow
    })
  }

  get removeMenuItem() {
    return {
      id: ActionName.REMOVE,
      text: this.contextMenuOptionsTexts.removeItem,
      iconCss: Icons.REMOVE,
      action: this.showDialog,
      mainButtonText: this.$t('action_buttons.remove'),
      secondaryButtonText: this.$t('action_buttons.cancel')
    }
  }

  get editMenuItem() {
    return {
      id: ActionName.EDIT,
      text: this.contextMenuOptionsTexts.editItem,
      iconCss: Icons.EDIT
    }
  }

  get lookMenuItem() {
    return {
      text: this.contextMenuOptionsTexts.lookItem,
      iconCss: Icons.VISIBLE
    }
  }

  get contextMenuItemsDefault() {
    const { lookItem, editItem, openWindow, removeItem } = this.contextMenuOptionsTexts
    return [
      ...this.addContextMenuOption(this.showLookRegisterContextMenuOption, {
        id: ActionName.EDIT,
        text: lookItem,
        iconCss: Icons.VISIBLE
      }),
      ...this.addContextMenuOption(this.showEditContextMenuOption, {
        id: ActionName.EDIT,
        text: editItem,
        iconCss: Icons.EDIT
      }),
      {
        id: ActionName.OPEN_WINDOW,
        text: openWindow,
        iconCss: Icons.OPEN_WINDOW
      },
      ...(this.showDeleteContextMenuOption
        ? [
            {
              id: ActionName.REMOVE,
              text: removeItem,
              iconCss: Icons.REMOVE,
              action: this.showDialog,
              mainButtonText: this.$t('action_buttons.remove'),
              secondaryButtonText: this.$t('action_buttons.cancel')
            }
          ]
        : [])
    ]
  }

  get renderedContextMenuItems() {
    if (this.databaseContextMenuItems && this.databaseContextMenuItems.length) {
      return renderContextMenuGridItemsHelper(this.databaseContextMenuItems)
    }
    if (this.contextMenuItems) {
      return this.contextMenuItems
    }
    return this.contextMenuItemsDefault
  }

  addContextMenuOption(checkIfRenderOption: boolean, { id, text, iconCss }: ContextMenuOption) {
    return checkIfRenderOption
      ? [
          {
            id,
            text,
            iconCss
          }
        ]
      : []
  }

  renderEditOrLookOptionInContextMenu = (contextMenuModule: any, contextMenuGridObject: any, canSave: number) => {
    const { editItem, lookItem } = this.contextMenuOptionsTexts
    const menuItemToKeep = canSave ? editItem : lookItem
    const menuItemToAdd = canSave ? this.editMenuItem : this.lookMenuItem
    const menuItemToRemove = canSave ? lookItem : editItem

    const { items } = contextMenuGridObject

    const menuItemsToKeep = items.filter((item: ContextMenuItemModel) => item.text === menuItemToKeep)
    const menuItemsToRemove = items.filter((item: ContextMenuItemModel) => item.text === menuItemToRemove)

    if (!menuItemsToKeep.length) {
      contextMenuModule.insertBefore([menuItemToAdd], menuItemToRemove, false)
      contextMenuGridObject.insertBefore([menuItemToAdd], menuItemToRemove, false)
      // Comprueba si el item que queremos mantener está repetido y si lo está eliminamos uno de ellos
    } else if (menuItemsToKeep.length > 1) {
      contextMenuModule.removeItems([menuItemToKeep])
      contextMenuGridObject.removeItems([menuItemToKeep])
    }

    menuItemsToRemove.forEach((_item: ContextMenuItemModel) => {
      contextMenuModule.removeItems([menuItemToRemove])
      contextMenuGridObject.removeItems([menuItemToRemove])
    })

    return menuItemToKeep
  }

  // se puede utilizar en el beforeOpen del context menu
  parsedItemsByPermissions(
    idEntity: number,
    items: ContextMenuItemModel[] | ContextMenuItem[],
    context: string = ContextName.DEFAULT
  ) {
    const { canSave, canDelete } = this.checkEntityPermissionsGetter(idEntity)
    const { editItem, lookItem, removeItem } = this.contextMenuOptionsTexts

    const itemsParsed: ContextMenuItem[] = []

    items.forEach((item: any) => {
      if ((item.text === lookItem || item.text === editItem) && context !== ContextName.SEARCH) {
        item.text = canSave ? editItem : lookItem
        item.iconCss = canSave ? Icons.EDIT : Icons.VISIBLE
      }
      if (item.text === removeItem && !canDelete) {
        return true
      }
      itemsParsed.push(item)
    })

    return itemsParsed
  }

  renderRemoveOptionInContextMenu(
    contextMenuModule: any,
    contextMenuGridObject: any,
    canDelete: number,
    buttonText: TranslateResult
  ) {
    if (canDelete) {
      contextMenuModule.insertAfter([this.removeMenuItem], buttonText, false)
      contextMenuGridObject.insertAfter([this.removeMenuItem], buttonText, false)
    }
  }
}
</script>
