export interface Report {
  id: string | null
  idCategory: string | null
  idType: string | null
  name: string | null
  reportName: string | null
  category: string | null
  config: string | object | null
  description: string | null
  date: string | null
}

export interface DashboardReport {
  idReport: string
  idOwnFile: string
  description: string
  startDate: string
  modifiedDate: string
  canEdit: string
  canDelete: string
}

export interface ReportList {
  alias: string
  aliasSysList: string
  category: string
  configFilters: string
  description: string
  id: string
  name: string
}

export interface ReportsState {
  dashboardReportsList: DashboardReport[]
  report: Report | null
  reportData: [] | null
  reports: Report[] | null
  selectedReportList: ReportList | null
  total: string
}

export const reportsMutationTypes = {
  DELETE_REPORT: 'DELETE_REPORT',
  FETCH_DASHBOARD_REPORTS: 'FETCH_DASHBOARD_REPORTS',
  FETCH_REPORT: 'FETCH_REPORT',
  FETCH_REPORTS: 'FETCH_REPORTS',
  FETCH_REPORT_DATA: 'FETCH_REPORT_DATA',
  FETCH_TOTAL_NUMBER_OF_REPORTS: 'FETCH_TOTAL_NUMBER_OF_REPORTS',
  POST_REPORT: 'POST_REPORT',
  RESET_REPORT: 'RESET_REPORT',
  SET_SELECTED_REPORT_LIST: 'SET_SELECTED_REPORT_LIST'
}
