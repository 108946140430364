import { ColumnModel, columnWidth, columnActions as columnActionDefault, CommandModel, GridConfiguration } from "@/components/grids/LfGrid/LfGridTypes"
import { ConfigurationTypes } from "@/store/modules/configuration/configurationTypes"
import store from "@/store/store"
import { ModuleNamespaces } from "@/store/types/storeGlobalTypes"
import { ComputedRef } from "vue"
import { Ref, ref } from "vue"

export interface PropsUseGridConfiguration {
  listName: string
  context: string
  commandButtons?: CommandModel[] | ComputedRef<CommandModel[]>
}

export interface ReturnUseGridConfiguration {
  getGridConfiguration: () => Promise<void>
  gridConfiguration: Ref<GridConfiguration>
}

export default function useGridConfiguration(props: PropsUseGridConfiguration): ReturnUseGridConfiguration {
  const gridConfiguration = ref()

  const getGridConfiguration = async () => {
    await store.dispatch(`${ModuleNamespaces.CONFIGURATION}/fetchCurrentListConfigurationContext`, {
      objectType: ConfigurationTypes.VIEW,
      alias: props.listName,
      context: props.context
    })
    const gridConfAux = store.getters[`${ModuleNamespaces.CONFIGURATION}/getCurrentListConfigurationContext`](props.context)
    if (!gridConfAux) {
      return
    }
    if (!props.commandButtons) {
      gridConfiguration.value = gridConfAux
      return
    }
    const column = gridConfAux.columns.find((item: ColumnModel) => item.commands)
    // TODO NIKI - ¿Se puede no pintar la columna de comandos? FACTURACIÓN/ AGRUPACION DE IMPUESTOS
    if (column === undefined) {
      const columnActions = { ...columnActionDefault }
      columnActions.width = getColumnActionWidth()
      columnActions.maxWidth = getColumnActionWidth()
      columnActions.commands = props.commandButtons as CommandModel[]
      gridConfAux.columns.push(columnActions)
    }
    gridConfiguration.value = gridConfAux
  }

  const getColumnActionWidth = (): number => {
    return columnWidth[commandButtonsLength()]
  }

  const commandButtonsLength = (): number => {
    if (!props.commandButtons) {
      return 0
    }
    return 'value' in props.commandButtons ? props.commandButtons.value.length : props.commandButtons.length
  }

  getGridConfiguration()

  return {
    getGridConfiguration,
    gridConfiguration
  }
}
