// https://www.userflow.com/home
// https://docs.userflow.com/docs/userflow-js#userflow-js-reference
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import userflow from 'userflow.js'
import store from '@/store/store'

export function userflowLogin() {

  userflow.init(process.env.VUE_APP_USERFLOW_TOKEN)

  const {
    id,
    ccNavision,
    userId,
    companyId,
    isDocsIAUser
  } = store.getters[`${ModuleNamespaces.AUTH}/getTrackUser`]

  userflow.identify(
    id,
    {
      ccNavision,
      userId,
      companyId,
      isDocsIAUser
    }
  )
}

export function userflowLogout() {
  userflow.reset()
}
