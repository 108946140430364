import { computed, Ref } from "vue"
import { GridComponent as EjsGrid } from '@syncfusion/ej2-vue-grids'

interface PropsUseGridFilterChips {
  gridRef: Ref<InstanceType<typeof EjsGrid> | null>
}

interface ResponseUseGridFilterChips {
  filters: any
  removeFilterByField: (field: string) => void
}

export default function useGridFilterChips(props: PropsUseGridFilterChips): ResponseUseGridFilterChips {
  const { gridRef } = props

  const gridInstance = computed(() => (gridRef.value as any)?.ej2Instances)

  const filters = computed(() => {
    if (gridRef.value) {
      const { columns } = (gridInstance.value as any)?.filterSettings
      return columns
    }
    return []
  })

  const removeFilterByField = (field: string) => {
    const filterResponse = filters.value.filter((item: any) => item.field !== field)
    ; (gridRef.value as any).filterSettings = filterResponse
  }

  return {
    removeFilterByField,
    filters
  }
}
