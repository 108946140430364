import { render, staticRenderFns } from "./ChangeStatusDialogComponent.vue?vue&type=template&id=36aa785d"
import script from "./ChangeStatusDialogComponent.vue?vue&type=script&lang=ts&setup=true"
export * from "./ChangeStatusDialogComponent.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports