import { Module } from 'vuex'
import { getters } from '@/store/modules/actions/actionsGetters'
import { actions } from '@/store/modules/actions/actionsActions'
import { mutations } from '@/store/modules/actions/actionsMutations'
import { ActionsState } from '@/store/modules/actions/actionsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: ActionsState = {
  action: [],
  actions: [],
  parentAction: null,
  duplicateActionId: null,
  selectedActionType: '',
  chronometerNewActionInfo: {
    start: '',
    end: '',
    duration: 0
  }

}

const namespaced: boolean = true

export const actionsModule: Module<ActionsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
