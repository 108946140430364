<template lang="pug">
div(class="word-preview")
  NotPreviewContentComponent(
    ref="NotPreviewContentComponentRef"
    v-show="isPreviewOpened && renderTypeOfComponent() === componentType.NotContent"
    @failPreview="onPreviewFail"
    @close-viewer-event="toggle"
  )
  WordPreviewComponent(
    ref="WordPreviewComponentRef"
    v-show="isPreviewOpened && renderTypeOfComponent() === componentType.Word"
    @failPreview="onPreviewFail"
    @close-viewer-event="toggle"
  )
  PdfPreviewComponent(
    ref="PdfPreviewComponentRef"
    v-show="isPreviewOpened && renderTypeOfComponent() === componentType.Pdf"
    @failPreview="onPreviewFail"
    @close-viewer-event="toggle"
  )
  ImagePreviewComponent(
    ref="ImagePreviewComponentRef"
    v-show="isPreviewOpened && renderTypeOfComponent() === componentType.Jpg"
    @failPreview="onPreviewFail"
    @close-viewer-event="toggle"
  )
  EmailPreviewComponent(
    ref="EmailPreviewComponentRef"
    v-show="isPreviewOpened && renderTypeOfComponent() === componentType.Eml"
    @failPreview="onPreviewFail"
    @close-viewer-event="toggle"
  )
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import {
  ComponentPreview,
  DocumentPreview,
  DocumentPreviewType,
  getPreviewComponentByExtension
} from '@/components/FileManager/Preview/types/PreviewTypes'
import { Splitter, PanePropertiesModel } from '@syncfusion/ej2-layouts'
import NotPreviewContentComponent from '@/components/FileManager/Preview/components/NotPreviewContentComponent.vue'
import PdfPreviewComponent from '@/components/FileManager/Preview/components/PdfPreviewComponent/PdfPreviewComponent.vue'
import WordPreviewComponent from '@/components/FileManager/Preview/components/WordPreviewComponent.vue'
import ImagePreviewComponent from '@/components/FileManager/Preview/components/ImagePreviewComponent/ImagePreviewComponent.vue'
import EmailPreviewComponent from '@/components/FileManager/Preview/components/EmailPreviewComponent/EmailPreviewComponent.vue'
import { FileManager } from '@syncfusion/ej2-vue-filemanager'

@Component({
  components: {
    NotPreviewContentComponent,
    PdfPreviewComponent,
    WordPreviewComponent,
    ImagePreviewComponent,
    EmailPreviewComponent
  }
})
export default class PreviewComponent extends Vue {
  @Prop({
    type: Object
  })
  documentPreview: DocumentPreview

  @Prop({
    type: FileManager
  })
  fileManagerInstance: FileManager

  previewExtensions = ['.pdf', '.doc', '.docx', '.rtf', '.txt', '.jpg', '.xyz', '.eml', '.msg']

  currentComponent: Element | Element[] | Vue | Vue[] = []
  currentDocument: DocumentPreview | null = null
  isPreviewOpened = false
  failPreview = false

  componentKey: number = 1

  componentType = {
    NotContent: ComponentPreview.NotContent,
    Pdf: ComponentPreview.Pdf,
    Word: ComponentPreview.Word,
    Jpg: ComponentPreview.Jpg,
    Eml: ComponentPreview.Email
  }

  texts = {
    preview: this.$t('components.file_manager.toolbar.preview').toString(),
    closePreview: this.$t('components.file_manager.toolbar.closePreview').toString()
  }

  @Watch('fileManagerInstance')
  onSetFileManagerInstance(instance: any) {
    if (instance) {
      this.fileManagerInstance.addEventListener('success', this.attachEvents)
      this.attachEvents()
    }
  }

  attachEvents() {
    this.fileManagerInstance.splitterObj.element.addEventListener('resizeStop', this.onSplitterResizeStop)
  }

  getPreviewExtensions() {
    return this.previewExtensions
  }

  async onPreviewFail() {
    this.hide()
    this.failPreview = true
    this.currentDocument = { id: 0, type: DocumentPreviewType.Unknown }
    await this.show(this.currentDocument)
  }

  onSplitterResizeStop(_args: any) {}

  refresh(documentPreview: DocumentPreview) {
    this.failPreview = false

    if (!this.hasFileManagerPreviewPanel()) {
      return
    }

    this.currentDocument = documentPreview
    this.hide()
    this.show(documentPreview)
  }

  toggle(documentPreview: DocumentPreview | null) {
    this.failPreview = false
    this.isPreviewOpened = false

    this.currentDocument = documentPreview

    if (!this.fileManagerInstance) {
      return
    }

    if (!documentPreview) {
      this.hide()
      return
    }

    if (this.hasFileManagerPreviewPanel()) {
      this.hide()
    } else {
      this.show(documentPreview)
    }
  }

  hide() {
    const previewContent = this.fileManagerInstance.element.querySelector('.preview-content')

    if (!previewContent || !previewContent.parentNode) {
      return
    }

    const mainContent = this.fileManagerInstance.element.querySelector('.e-layout .e-navigation')
    const detailContent = this.fileManagerInstance.element.querySelector('.e-layout .e-layout-content')

    let flexMain = ''
    let flexDetail = ''

    if (mainContent && detailContent) {
      const { flexBasisMain, flexBasisDetail } = this.getFlexBasisClose()
      flexMain = flexBasisMain
      flexDetail = flexBasisDetail
    }

    previewContent.parentNode.removeChild(previewContent)

    const splitterInstance: Splitter = this.fileManagerInstance.splitterObj
    splitterInstance.removePane(2)

    this.changeMainPreviewIconText()

    if (mainContent && detailContent) {
      ;(mainContent as any).style.flexBasis = flexMain
      ;(detailContent as any).style.flexBasis = flexDetail
    }
  }

  async show(documentPreview: DocumentPreview) {
    const mainContent = this.fileManagerInstance.element.querySelector('.e-layout .e-navigation')
    const detailContent = this.fileManagerInstance.element.querySelector('.e-layout .e-layout-content')

    if (!mainContent || !detailContent) {
      return { flexBasisMain: '', flexBasisDetail: '' }
    }

    const { flexBasisMain, flexBasisDetail } = this.getFlexBasisOpen()

    ;(detailContent as any).style.flexBasis = flexBasisDetail

    const previewPanel = this.createFileManagerPreviewPanel(documentPreview.id)
    this.addPanelToFileManagerSplitter(previewPanel)

    this.setPreviewPanelFlexBasis()
    ;(mainContent as any).style.flexBasis = flexBasisMain
    ;(detailContent as any).style.flexBasis = flexBasisDetail

    await this.$nextTick()

    this.addComponentToPanel()

    this.isPreviewOpened = true

    this.currentComponent = this.getComponentByDocument(documentPreview) || ({} as Vue)

    if (this.currentComponent !== null) {
      ;(this.currentComponent as any).preview(documentPreview)
    }

    this.failPreview = false

    this.changeMainPreviewIconText()
  }

  getComponentByDocument(documentPreview: DocumentPreview): Element | Element[] | Vue | Vue[] | undefined {
    const componentName = getPreviewComponentByExtension(documentPreview.type as DocumentPreviewType)
    const componentRef = this.$refs[`${componentName}Ref`]

    if (componentRef) {
      return componentRef as Vue
    }
    return undefined
  }

  setPreviewPanelFlexBasis() {
    const splitterInstance: Splitter = this.fileManagerInstance.splitterObj
    const previewPanelWrapper = splitterInstance.element.childNodes
    previewPanelWrapper.forEach((value: any) => {
      const html = value as HTMLElement
      if (html && html.firstChild && html!.firstElementChild!.id.includes('preview-wrapper')) {
        html.style.flexBasis = '50%'
      }
    })
  }

  getFlexBasisOpen() {
    const mainContent = this.fileManagerInstance.element.querySelector('.e-layout .e-navigation')
    const detailContent = this.fileManagerInstance.element.querySelector('.e-layout .e-layout-content')

    if (!mainContent || !detailContent) {
      return { flexBasisMain: '', flexBasisDetail: '' }
    }

    let flexBasisMain = '25%'
    let flexBasisDetail = '25%'

    const flexBasisM = (mainContent as any).style.flexBasis
    const flexBasisD = (detailContent as any).style.flexBasis

    let detail = Number(flexBasisD.replace('%', ''))
    const main = Number(flexBasisM.replace('%', ''))

    if (!detail && main) {
      detail = 100 - main
    }

    if (detail && main && main + detail > 50) {
      detail = 50 - main
    }

    flexBasisDetail = `${detail}%`
    flexBasisMain = `${main}%`

    return { flexBasisMain, flexBasisDetail }
  }

  getFlexBasisClose() {
    const mainContent = this.fileManagerInstance.element.querySelector('.e-layout .e-navigation')
    const detailContent = this.fileManagerInstance.element.querySelector('.e-layout .e-layout-content')

    if (!mainContent || !detailContent) {
      return { flexBasisMain: '', flexBasisDetail: '' }
    }

    let flexBasisMain = '25%'
    let flexBasisDetail = '75%'

    const flexBasisM = (mainContent as any).style.flexBasis
    const flexBasisD = (detailContent as any).style.flexBasis

    let detail = Number(flexBasisD.replace('%', ''))
    let main = Number(flexBasisM.replace('%', ''))

    if (main) {
      detail = 100 - main
    }

    if (detail && !main) {
      main = 100 - detail
    }

    flexBasisDetail = `${detail}%`
    flexBasisMain = `${main}%`

    return { flexBasisMain, flexBasisDetail }
  }

  renderTypeOfComponent() {
    if (!this.currentDocument || this.failPreview) {
      return ComponentPreview.NotContent
    }

    const componentName: string | undefined = getPreviewComponentByExtension(
      this.currentDocument.type as DocumentPreviewType
    )

    this.failPreview = false

    return componentName || ComponentPreview.NotContent
  }

  addComponentToPanel() {
    const component = this.getComponentByDocument(this.currentDocument as DocumentPreview)

    if (!component || !this.currentDocument) {
      return
    }

    const parent = this.fileManagerInstance.element.querySelector(`#preview-wrapper-${this.currentDocument.id}`)

    if (parent) {
      parent.appendChild((component as any).$el)
    }
  }

  async addPanelToFileManagerSplitter(content: string | HTMLElement | undefined) {
    const splitterInstance: Splitter = this.fileManagerInstance.splitterObj
    const props: PanePropertiesModel = {
      content,
      min: '100px'
    }
    splitterInstance.addPane(props, 2)
  }

  createFileManagerPreviewPanel(documentId: number): HTMLDivElement {
    const previewContent = document.createElement('div')
    previewContent.id = `preview-wrapper-${documentId}`
    previewContent.classList.add('preview-content')
    return previewContent
  }

  hasFileManagerPreviewPanel(): boolean {
    return (this.fileManagerInstance.splitterObj as any).allPanes.some((p: any) => {
      return p.firstChild && p.firstChild.id.includes('preview-wrapper')
    })
  }

  changeMainPreviewIconText() {
    const mainPreviewButton = this.fileManagerInstance.element.querySelector('.e-toolbar-right .main-preview-button')
    const mainPreviewButtonText = this.fileManagerInstance.element.querySelector(
      '.e-toolbar-right .main-preview-button + .e-tbar-btn-text'
    )

    if (!mainPreviewButton) {
      return
    }

    if (mainPreviewButton.classList.contains('lf-icon-not-visible')) {
      mainPreviewButton.classList.remove('lf-icon-not-visible')
      mainPreviewButtonText!.innerHTML = this.texts.closePreview
    }

    if (mainPreviewButton.classList.contains('lf-icon-visible')) {
      mainPreviewButton.classList.remove('lf-icon-visible')
      mainPreviewButtonText!.innerHTML = this.texts.preview
    }

    if (this.hasFileManagerPreviewPanel()) {
      mainPreviewButton.classList.add('lf-icon-not-visible')
      mainPreviewButtonText!.innerHTML = this.texts.closePreview
    } else {
      mainPreviewButton.classList.add('lf-icon-visible')
      mainPreviewButtonText!.innerHTML = this.texts.preview
    }
  }
}
</script>

<style lang="scss">
.e-filemanager .preview-content {
  display: flex;
  position: relative;
  height: 100% !important;
  margin: 0px;
}
</style>
