<template lang="pug">
  section(v-if="!showSpinnerLayer" class="lexon-view-container")
    GridTable(
      v-if="Object.keys(listConfiguration).length && Object.keys(serverSideData).length > 0 && !showSpinnerLayer"
      ref="grid"
      :gridConfiguration="configList"
      :title="$t('views.contacts.title')"
      :toolbarOptions="toolbarOptionsCustom"
      :contextMenuItems="contextMenuItems"
      :listName="listConfiguration['Alias']"
      :context="context"
      :itemsData="serverSideData"
      :itemsDataExport="serverSideExportData"
      :useServerPagination="useServerPagination"
      :commandClick="commandClick"
      @cellClicked="onCellClicked"
      @gridActionChange="gridActionChange"
      @gridExportData="gridExportData"
      @contextMenuClicked="onContextMenuClicked"
      @toolbarClicked="onToolbarClicked"
    )

  </template>

<script lang="ts">
import { mixins } from 'vue-class-component'
import { Component } from 'vue-property-decorator'
import { ParentCategory } from '@/store/modules/menus/menusTypes'
import GridTable from '@/components/grids/GridTable/GridTableComponent.vue'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { ContactTypes, ContactItem } from '@/store/modules/contacts/contactsTypes'
import ListViewMixin from '@/mixins/ListViewMixin.vue'
import { ListNames, ListConfiguration } from '@/store/modules/configuration/configurationTypes'
import { Action, Getter, Mutation } from 'vuex-class'
import { CommandClickEventArgs, DataResult } from '@syncfusion/ej2-vue-grids'
import { formatFieldsTypeDateEPOCH } from '@/helpers/dateTime'
import { Icons } from '@/icons/icons'
import useGridSaveUserConf, { RequestParams } from '@/composables/useGridSaveUserConf'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import {
  columnsChooserToolbarItem,
  filterToolbarItem,
  kebakMenuToolbarItem,
  searchToolbarItem
} from '@/components/grids/LfGrid/components/ToolbarContextMenu/ToolbarContextMenuType'
import useContacts from '@/views/contacts/composables/useContacts'
import { gridConfigurationWithActionColumn } from '@/helpers/grid'
import { ActionName, CommandModel } from '@/components/grids/LfGrid/LfGridTypes'
import { TrackerEvents, trackEvent } from '@/plugins/tracker'

const contactsModule = ModuleNamespaces.CONTACTS
const configurationModule = ModuleNamespaces.CONFIGURATION
const dialogModule = ModuleNamespaces.DIALOG
const entitiesModule = ModuleNamespaces.ENTITIES

const { parseUserConfig } = useGridSaveUserConf()
const { openContact } = useContacts()

@Component({
  components: {
    GridTable
  }
})
export default class ContactsView extends mixins(ListViewMixin) {
  useServerPagination: boolean = true

  serverSideData: DataResult | object = {}
  serverSideExportData: DataResult | object = {}

  @Action('showDialog', { namespace: dialogModule })
  showDialogAction: ({}) => {}

  @Mutation('SAVE_MENU_PARENT_CATEGORY_NAME', { namespace: entitiesModule })
  saveMenuParentCategoryName: (parentCategoryName: string) => void

  @Action('deleteContact', { namespace: contactsModule })
  deleteContact: ({ contactType, idContact }: { contactType: string; idContact: string }) => boolean

  icons = {
    natural_person: Icons.USER,
    legal_entity: Icons.COMPANY
  }

  get context() {
    return ContextName.CONTACT_ALL
  }

  get configList() {
    const config = JSON.parse(this.listConfiguration['Config'])
    const { canDelete } = this.viewPermission
    const commandBtns: CommandModel[] = canDelete
      ? [
          {
            title: this.$t('action_buttons.open_new_window').toString(),
            type: 'None',
            buttonOption: {
              iconCss: Icons.OPEN_WINDOW,
              cssClass: 'custombutton'
            }
          },
          {
            title: this.$t('action_buttons.remove').toString(),
            type: 'None',
            buttonOption: {
              iconCss: Icons.REMOVE,
              cssClass: 'custombutton'
            }
          }
        ]
      : [
          {
            title: this.$t('action_buttons.open_new_window').toString(),
            type: 'None',
            buttonOption: {
              iconCss: Icons.OPEN_WINDOW,
              cssClass: 'custombutton'
            }
          }
        ]

    const gridConfiguration = gridConfigurationWithActionColumn(config, commandBtns)
    return JSON.stringify(gridConfiguration)
  }

  @Action('fetchContactsFilter', { namespace: contactsModule })
  fetchContactsFilter: ({ contactType, params }: { contactType: string; params: RequestParams }) => []

  @Getter('getPaginateContacts', { namespace: contactsModule })
  contactsDataPaginated: []

  @Getter('getListConfig', { namespace: configurationModule })
  listConfig: ListConfiguration

  async created() {
    await this.fetchCurrentListConfiguration(ListNames.CONTACTS)
    await this.loadData()
    this.saveMenuParentCategoryName(ParentCategory.CONTACTS)
    this.saveSelectedEntityName(ContactTypes.CONTACTS_ALL)
  }

  async loadData() {
    try {
      if (this.useServerPagination) {
        const params = await parseUserConfig(this.listConfig)
        await this.fetchContactsFilter({ contactType: ContactTypes.CONTACTS_ALL, params })
        this.serverSideData = this.formatDataPaginated(this.contactsDataPaginated)
      } else {
        await this.fetchContacts(ContactTypes.CONTACTS_ALL)
        this.serverSideData = this.contactsDataPaginated
      }
    } catch (error) {
    } finally {
      this.hideSpinnerLayerAction()
    }
  }

  formatDataPaginated(data: any) {
    const items = formatFieldsTypeDateEPOCH(data.data)
    const gridResult: DataResult = { result: items, count: parseInt(data.pagination.totalRecs.toString(), 10) }
    return gridResult
  }

  async gridActionChange(serverSideParams: string) {
    const params = JSON.parse(serverSideParams)
    await this.fetchContactsFilter({ contactType: ContactTypes.CONTACTS_ALL, params })
    this.serverSideData = this.formatDataPaginated(this.contactsDataPaginated)
  }

  async gridExportData(serverSideParams: string) {
    const params = JSON.parse(serverSideParams)
    await this.fetchContactsFilter({ contactType: ContactTypes.CONTACTS_ALL, params })
    const { result } = this.formatDataPaginated(this.contactsDataPaginated)
    this.serverSideExportData = result
  }

  onToolbarClicked(args: any) {
    const { item } = args
    const { id } = item

    if (id === ActionName.ADD) {
      args.cancel = true
      trackEvent(TrackerEvents.CREATE_CONTACT)
      openContact()
    }
  }

  async onContextMenuClicked(args: any, selectedRegister: any) {
    if (args.item) {
      if (args.item.text === this.$t('components.context_menu.edit')) {
        args.cancel = true
        trackEvent(TrackerEvents.EDIT_CONTACT)
        await openContact(selectedRegister.id)
      }
      if (args.item.text === this.$t('components.context_menu.open_window')) {
        args.cancel = true
        trackEvent(TrackerEvents.EDIT_CONTACT)
        await openContact(selectedRegister.id, '_blank')
      }
    }
  }

  async onCellClicked() {
    trackEvent(TrackerEvents.EDIT_CONTACT)
  }

  get toolbarOptionsCustom() {
    const { canSave } = this.viewPermission
    return [
      searchToolbarItem,
      filterToolbarItem,
      ...(canSave
        ? [
            {
              id: ActionName.ADD,
              text: this.$t('components.grid_table.tooltip.new_contact'),
              align: 'right',
              tooltipText: this.$t('components.grid_table.tooltip.new_contact'),
              cssClass: 'lf-btn-model2'
            }
          ]
        : [{}]),
      columnsChooserToolbarItem,
      kebakMenuToolbarItem
    ]
  }

  commandClick(args: CommandClickEventArgs): void {
    const action = args.commandColumn?.title
    const { id, name } = args.rowData as ContactItem
    switch (action) {
      case this.$t('action_buttons.remove'):
        this.deleteItem(id, name)
        break
      case this.$t('action_buttons.open_new_window'):
        trackEvent(TrackerEvents.EDIT_CONTACT)
        openContact(id, '_blank')
        break
      default:
        break
    }
  }

  deleteItem(id: string, name: string) {
    this.showDialogAction({
      type: DialogTypes.INFO,
      message: this.$t('components.dialog.remove_register_text', {
        register: name,
        text: this.$t('components.dialog.contacts_literal')
      }),
      action: () => {
        this.confirmDelete(id, name)
      },
      mainButtonText: this.$t('action_buttons.remove'),
      secondaryButtonText: this.$t('action_buttons.cancel')
    })
  }
  async confirmDelete(idContact: string, name: string) {
    const data = await this.deleteContact({ contactType: ContactTypes.CONTACTS_ALL, idContact })
    if (!data) {
      return
    }
    this.showDialogAction({
      type: DialogTypes.SUCCESS,
      message: this.$t('components.dialog.success_removed_register', {
        register: name,
        text: this.$t('components.dialog.contacts_literal')
      })
    })
    ;(this as any).$refs.grid.refresh()
  }
}
</script>
