
import { extend } from 'vee-validate';
import { i18n } from '@/plugins/vue-i18n'; // Asume que tienes configurado vue-i18n correctamente

const urlRegex = /^(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})$/;

extend('url', {
  validate(value) {
    return urlRegex.test(value);
  },
  message: i18n.t('validations.invalid_url') as string
});
